import { Box, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@lib/themes";
import Waves from "@public/waves.svg";
import FirstIcon from "@public/login-icon-0.svg";
import SecondIcon from "@public/login-icon-1.svg";
import ThirdIcon from "@public/login-icon-2.svg";
import FourthIcon from "@public/login-icon-3.svg";
import FifthIcon from "@public/login-icon-4.svg";
import SixthIcon from "@public/login-icon-5.svg";
import SeventhIcon from "@public/login-icon-6.svg";
import FooterPattern from "./FooterPattern";

const useStyles = makeStyles((theme, props) => ({
  root: {
    backgroundColor: !props.forErrorPage && theme.palette.background.default,
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    zIndex: "1",
  },
  icon: {
    width: "100%",
    "& stop": {
      stopColor: theme.palette.backgroundLines,
    },
  },
  wavesIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 2,
    width: "100%",
    opacity: 0.1,
  },
  footerIcon: {
    position: "absolute",
    left: 0,
    bottom: 0,
    // width: "100%",
    width: "100%",
    objectFit: "contain",
    height: "100%",
  },
  firstIcon: {
    position: "absolute",
    left: 217,
    bottom: 123,
  },
  secondIcon: {
    position: "absolute",
    left: 512,
    bottom: 120,
  },
  thirdIcon: {
    position: "absolute",
    left: 80,
    bottom: 244,
  },
  fourthIcon: {
    position: "absolute",
    left: 130,
    bottom: 367,
  },
  fifthIcon: {
    position: "absolute",
    left: 354,
    bottom: 434,
  },
  sixthIcon: {
    position: "absolute",
    left: 454,
    bottom: 285,
  },
  seventhIcon: {
    position: "absolute",
    left: 670,
    bottom: 232,
  },
  iconsContainer: {
    zIndex: "2",
    position: "absolute",
    top: 0,
    left: props.floatRight ? "49%" : 0,
    width: "100%",
    height: "100%",
    opacity: 0.1,
  },
  footerPattern: {
    opacity: 0.4,
  },
}));

interface BackgroundPatternProps {
  floatRight?: boolean;
  forErrorPage?: boolean;
}

export default function BackgroundPattern({
  floatRight = false,
  forErrorPage,
}: BackgroundPatternProps) {
  const classes = useStyles({
    floatRight,
    forErrorPage,
  });
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box className={classes.root}>
      {!isMobile && (
        <>
          <Waves className={classes.wavesIcon} />
          <Box className={classes.iconsContainer}>
            <FirstIcon className={classes.firstIcon} />
            <SecondIcon className={classes.secondIcon} />
            <ThirdIcon className={classes.thirdIcon} />
            <FourthIcon className={classes.fourthIcon} />
            <FifthIcon className={classes.fifthIcon} />
            <SixthIcon className={classes.sixthIcon} />
            <SeventhIcon className={classes.seventhIcon} />
          </Box>
          {!forErrorPage && (
            <Box zIndex="1" className={classes.footerPattern}>
              <FooterPattern />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
